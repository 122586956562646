import {isBoolean, isNumber, isString, isStringOrUndefined} from '@/helpers/TypeGuard';
import {z} from 'zod';

export const AssessmentSchema = z.object({
    id: z.number(),
    teacherId: z.number(),
    authorId: z.number(),
    score: z.number(),
    timestamp: z.coerce.date(),
    authorName: z.string(),
    organisationName: z.string(),
    status: z.string(),
    name: z.string(),
    date: z.string(),
    isClosed: z.boolean(),
    type: z.enum(['observation', 'student-perception', 'evaluation'])
});

export default abstract class Assessment {
    public readonly id: number;
    public readonly teacherId: number;
    public readonly authorId: number;
    public readonly score: number|undefined;
    public readonly timestamp: number;
    public readonly authorName: string;
    public readonly organisationName: string;
    public readonly status: string|undefined;
    public readonly name: string;
    public readonly date: string;
    public readonly isClosed: boolean;
    public readonly type: 'observation'|'student-perception'|'evaluation';

    protected constructor(data: Record<string, unknown>, type: 'observation'|'student-perception'|'evaluation') {
        this.id = isNumber(data, 'id');
        this.teacherId = isNumber(data, 'teacherId');
        this.authorId = isNumber(data, 'authorId');
        this.score = isNumber(data, 'score');
        this.timestamp = isNumber(data, 'timestamp') * 1000;
        this.authorName = isString(data, 'authorName');
        this.organisationName = isString(data, 'organisationName');
        this.name = isString(data, 'name');
        this.date = isString(data, 'date');
        this.status = isStringOrUndefined(data, 'status');
        this.isClosed = isBoolean(data, 'isClosed');
        this.type = type;
    }

    protected getDate() {
        return new Date(this.timestamp);
    }

    public getDateISO() {
        if (!this.timestamp) {
            return undefined;
        }

        return new Date(this.timestamp).toISOString().slice(0, -14);
    }
}
