import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleForm = _resolveComponent("SimpleForm")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.user?.role === 'observer')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(" Vul de code van de docent in om toegang te vragen tot het profiel van de docent. "),
          _createVNode(_component_SimpleForm, {
            target: `wp-json/api/teacher/${_ctx.uid}/request-access/`,
            alerts: 'html',
            "success-text": _ctx.successMessage,
            onResponse: _ctx.onResponse
          }, {
            default: _withCtx(() => [
              _createElementVNode("input", {
                type: "text",
                class: "user-code-input",
                autocomplete: "off",
                name: "teacherUid",
                min: "10",
                placeholder: "Docent code...",
                autofocus: "",
                onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.inputUid && _ctx.inputUid(...args)))
              }, null, 32),
              _createElementVNode("button", {
                disabled: _ctx.uid.length < 10,
                class: "btn btn-primary float-end mt-3"
              }, " Docent uitnodigen ", 8, _hoisted_1)
            ]),
            _: 1
          }, 8, ["target", "success-text", "onResponse"])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.user?.role === 'teacher')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createTextVNode(" Vul de code van de observator in om deze direct toegang te geven tot uw profiel. "),
          _createVNode(_component_SimpleForm, {
            target: `wp-json/api/observer/grant-instant-access/`,
            alerts: 'html',
            "success-text": _ctx.successMessage,
            onResponse: _ctx.onResponse
          }, {
            default: _withCtx(() => [
              _createElementVNode("input", {
                type: "text",
                class: "user-code-input",
                autocomplete: "off",
                name: "observerUid",
                min: "10",
                placeholder: "Observator code...",
                autofocus: "",
                onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.inputUid && _ctx.inputUid(...args)))
              }, null, 32),
              _createElementVNode("button", {
                disabled: _ctx.uid.length < 10,
                class: "btn btn-primary float-end mt-3"
              }, " Observator toegang geven ", 8, _hoisted_2)
            ]),
            _: 1
          }, 8, ["success-text", "onResponse"])
        ], 64))
      : _createCommentVNode("", true),
    (!_ctx.user)
      ? (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}