<template>
    <suspense>
        <RouterView/>
        <template #fallback><ProgressSpinner/></template>
    </suspense>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';

export default defineComponent({
    name: 'App',
    components: {ProgressSpinner}
})
</script>
