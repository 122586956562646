import {isBoolean} from '@/helpers/TypeGuard';
import Assessment from '@/models/Assessment';

export default class Perception extends Assessment {
    public readonly isInProgress: boolean;

    constructor(data: Record<string, unknown>) {
        super(data, 'student-perception');
        this.isInProgress = isBoolean(data, 'isInProgress');
    }
}
