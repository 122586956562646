<template>
    <div class="h-100 d-flex justify-content-center align-content-center align-items-center m-auto">
        <SimpleIcon icon="loading" :size="size"/>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import SimpleIcon from '@/components/standalone/SimpleIcon.vue';

export default defineComponent({
    name: 'ProgressSpinner',
    components: {SimpleIcon},
    props: {
        size: {type: Number, default: 36}
    }
})
</script>
