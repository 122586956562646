<template>
    <div class="evaluation-general">
        <form v-if="evaluation" ref="form" :target="`wp-json/api/evaluation/${evaluationId}/info`">
            <BsCard title="Evaluatie" class="w-100">
                <textarea class="form-control" rows="20" name="evaluationContent" @input="onChange" v-html="evaluation.content"/>
                <small class="text-black-50">{{ statusText }}</small>
            </BsCard>
        </form>
        <ProgressSpinner v-else/>
    </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import BsCard from '@/components/bootstrap/BsCard.vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import useEvaluation from '@/composeables/useEvaluation';
import { fetchPost } from '@/helpers/fetchHelper';

export default defineComponent({
    name: 'EvaluationGeneral',
    components: {ProgressSpinner, BsCard},
    props: {
        evaluationId: {type: Number, required: true}
    },
    setup(props) {
        const form = ref<HTMLFormElement>();
        const statusText = ref('');
        let timeoutId = 0;

        return {
            form,
            statusText,
            evaluation: useEvaluation().evaluation,
            onChange() {
                if (!timeoutId) {
                    statusText.value = 'Saving...';

                    timeoutId = setTimeout(() => {
                        fetchPost(`wp-json/api/evaluation/${props.evaluationId}/`, new FormData(form.value));
                        timeoutId = 0;
                        statusText.value = '';
                    }, 500);
                }
            }
        }
    }
})
</script>
