import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_FetchButton = _resolveComponent("FetchButton")!

  return (_ctx.assessment.status === 'allowed')
    ? (_openBlock(), _createBlock(_component_RouterLink, {
        key: 0,
        to: _ctx.getRoute(_ctx.assessment)
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString((_ctx.user?.id === _ctx.assessment.authorId && !_ctx.assessment.isClosed) ? 'Ga verder' : 'Bekijk'), 1)
        ]),
        _: 1
      }, 8, ["to"]))
    : (_ctx.assessment.status === 'disallowed')
      ? (_openBlock(), _createBlock(_component_FetchButton, {
          key: 1,
          method: "post",
          target: `wp-json/api/${_ctx.assessment.type}/${_ctx.assessment.id}/request-access`,
          class: "btn btn-primary",
          onResponse: _ctx.onResponse
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Vraag Toegang ")
          ]),
          _: 1
        }, 8, ["target", "onResponse"]))
      : (_ctx.assessment.status === 'requested')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Toegang is gevraagd. De docent heeft nog niet gereageerd. "))
        : _createCommentVNode("", true)
}