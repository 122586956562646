import { resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuBar = _resolveComponent("MenuBar")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_GlobalModal = _resolveComponent("GlobalModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_Suspense, null, {
        fallback: _withCtx(() => [
          _createVNode(_component_ProgressSpinner)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_MenuBar)
        ]),
        _: 1
      })),
      (_openBlock(), _createBlock(_Suspense, null, {
        fallback: _withCtx(() => [
          _createVNode(_component_ProgressSpinner)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_RouterView)
        ]),
        _: 1
      }))
    ]),
    _createVNode(_component_GlobalModal)
  ], 64))
}