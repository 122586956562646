<template>
    <span>{{ timeString }}</span>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';

const MS_PER_HOUR = 3600000;
const MS_PER_MINUTE = 60000;
const MS_PER_SECOND = 1000;

export default defineComponent({
    name: 'TimestampCountdown',
    props: {
        toTimestamp: {type: Number, required: true}
    },
    setup(props) {
        const timeString = ref('');

        const intervalId = setInterval(() => {
            const diff = props.toTimestamp - (new Date()).getTime();

            if (diff > 0) {
                const s = Math.floor(diff / MS_PER_SECOND) % 60;
                const m = Math.floor(diff / MS_PER_MINUTE) % 60;
                const h = Math.floor(diff / MS_PER_HOUR);

                const ts = `0${s}`.slice(-2);
                const tm = `0${m}`.slice(-2);
                const th = `0${h}`.slice(-2);

                timeString.value = `${th}:${tm}:${ts}`;
            } else {
                timeString.value = '00:00:00';

                if (intervalId) {
                    clearInterval(intervalId);
                }
            }
        }, 500);

        return {timeString};
    }
})
</script>
