import XhrResource from '@/helpers/XhrResource';
import {z} from 'zod';

const resource = new XhrResource((v: unknown) => z.record(z.string()).parse(v));

export default () => ({
    load() {
        return resource.load(`wp-json/api/get-schools/`)
    },
    schools: resource.get(),
});
