import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "btn btn-primary text-white disabled cursor-not-allowed"
}
const _hoisted_2 = {
  key: 1,
  type: "submit",
  class: "btn btn-primary text-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createVNode(_component_ProgressSpinner, { size: 28 })
      ]))
    : (_openBlock(), _createElementBlock("button", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ]))
}