<template>
    <div class="row__actions">
        <CreateItemButton v-if="user?.role === 'observer'" :type="'observation'" :teacher-id="teacherId" class="btn btn-outline-primary">
            {{ (teacher?.activeObservationId) ? 'Ga verder met observatie' : 'Start nieuwe observatie' }}
        </CreateItemButton>
    </div>

    <ChartGraph v-if="observations?.length" :observations="observations"/>

    <section class="observation-overview">
        <table v-if="observations?.length" class="table table-light mb-0">
            <thead>
                <tr class="table-heading">
                    <th>Datum observatie</th>
                    <th>Observator</th>
                    <th>Organisatie</th>
                    <th/>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(observation, key) in observations" :key="key">
                    <td class="text-primary fw-bold">{{ observation.date }}</td>
                    <td>{{ observation.authorName }}</td>
                    <td>{{ observation.organisationName }}</td>
                    <td class="lookup text-end"><AccessStatusButton :assessment="observation"/></td>
                </tr>
            </tbody>
        </table>

        <div class="table__footer">
            <ProgressSpinner v-if="!observations"/>
            <div v-else-if="!observations.length" class="row__notification">
                Deze docent heeft nog geen observaties.
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import useObservations from '@/composeables/useObservations';
import AccessStatusButton from '@/components/observation/AccessStatusButton.vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import CreateItemButton from '@/components/form/CreateItemButton.vue';
import useCurrentUser from '@/composeables/useCurrentUser';
import ChartGraph from '@/components/charts/ChartGraph.vue';
import useTeacher from '@/composeables/useTeacher';

export default defineComponent({
    name: 'ObservationList',
    components: {ChartGraph, ProgressSpinner, AccessStatusButton, CreateItemButton},
    props: {
        teacherId: {required: true, type: Number}
    },
    async setup(props) {
        await useTeacher().load(props.teacherId);
        useObservations().load(props.teacherId);

        return {
            teacher: useTeacher().teacher,
            observations: useObservations().observations,
            user: useCurrentUser().user
        }
    }
})
</script>
