<template>
    <FetchButton :target="`wp-json/api/teacher/${teacherId}/${type}s/create`" method="POST" @response="onCreate">
        <slot/>
    </FetchButton>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import FetchButton from '@/components/form/FetchButton.vue';
import {IcaltResponse} from '@/types/IcaltResponse';
import {useRouter} from 'vue-router';
import {Routes} from '@/router';
import useEvaluations from '@/composeables/useEvaluations';
import useObservations from '@/composeables/useObservations';

export default defineComponent({
    name: 'CreateItemButton',
    components: {FetchButton},
    props: {
        teacherId: {type: Number, required: true},
        type: {type: String as PropType<'evaluation'|'observation'|'student-perception'>, required: true}
    },
    setup(props) {
        const router = useRouter();

        return {
            onCreate(response: IcaltResponse) {
                if (response.success && typeof response.data === 'number') {
                    switch (props.type) {
                        case 'evaluation':
                            useEvaluations().clear();
                            router.push({name: Routes.EvaluationGeneralInfo, params: {evaluationId: response.data}});
                            break;
                        case 'observation':
                            useObservations().clear();
                            router.push({name: Routes.ObservationGeneralInfo, params: {observationId: response.data}});
                            break;
                        // case 'student-perception':
                        //     usePerceptions().clear();
                        //     router.push({name: Routes.StudentPerceptionGeneralInfo, params: {perceptionId: response.data}});
                        //     break;
                        default:
                            throw 'Illegal create-item type.'
                    }
                } else {
                    window.alert(response.errors.join(', '));
                }
            }
        }
    }
})
</script>
