<template>
    <div class="card">
        <div class="card-header py-3">
            <h5 class="text-primary fw-bold fs-6 mb-0">{{ title }}</h5>
        </div>

        <div class="card-body">
            <slot/>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'BsCard',
    props: {
        title: {type: String, default: ''}
    }
})
</script>