import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import VueGtag from 'vue-gtag-next';
import 'bootstrap';
import '@/assets/scss/vue-app.scss';

const app = createApp(App);

app.use(VueGtag, {property: {id: 'GTM-NK34QQCD'}});
app.use(router).mount('#app');
