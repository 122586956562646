<template>
    <button v-if="isSecureContext" class="unstyled cursor-copy clickable-text" @click="copyText">
        &#128203;&nbsp;<slot/>
    </button>
    <span v-else tabindex="0">
        <slot/>
    </span>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'ClipboardButton',
    props: {
        text: {type: String, required: true}
    },
    setup(props) {
        return {
            isSecureContext: window.isSecureContext,
            copyText (e: PointerEvent & {target: HTMLElement}) {
                if (window.isSecureContext) {
                    try {
                        e.target.style.fontWeight = 'bolder';
                        window.navigator.clipboard.writeText(props.text);
                    } catch (err) {
                        window.alert(`Could not copy to clipboard. ${err}`);
                    }
                }
            },
        }
    }
})
</script>
