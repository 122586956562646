import {IcaltResponse} from '@/types/IcaltResponse';

export async function fetchPost(target: string, body: BodyInit): Promise<IcaltResponse> {
    const rawResponse = await fetch(`${window.location.origin}/${target}`, {method: 'POST', body});
    return rawResponse.json();
}

export async function fetchGet(target: string): Promise<IcaltResponse> {
    const rawResponse = await fetch(`${window.location.origin}/${target}`, {method: 'GET'});
    return rawResponse.json();
}
