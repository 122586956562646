import {TeacherSchema} from '@/models/Teacher';
import XhrResource from '@/helpers/XhrResource';

const resource = new XhrResource((data) => TeacherSchema.parse(data));

export default () => ({
    load(teacherId: number) {
        return resource.load(`wp-json/api/teacher/${teacherId}/`);
    },
    clear: () => resource.clear(),
    teacher: resource.get()
});
