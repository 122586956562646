<template>
    <SimpleForm target="wp-json/api/me/info/" alerts="html" @submit="onSubmit" @response="onResponse">
        <BsCard title="Profiel" class="w-100 pb-0">
            <div class="float-end">
                <button type="button" class="btn btn-dark float-end" @click="changePassword">
                    Wachtwoord opnieuw instellen.
                </button>
            </div>

            <div class="mb-3">
                <label class="form-label fw-bold w-50">
                    E-mailadres:
                    <input class="form-control" type="email" name="email" :value="user?.email" :disabled="isLoading">
                    <!--                    <span class="text-muted">Wanneer u uw E-mailadres aanpast zal u worden gevraagd om opnieuw in te loggen.</span>-->
                </label>
            </div>

            <div class="mb-3">
                <label class="form-label fw-bold w-25">
                    Voornaam:
                    <input class="form-control" type="text" name="firstName" :value="user?.firstName" :disabled="isLoading">
                </label>

                <label class="form-label fw-bold w-25">
                    Achternaam:
                    <input class="form-control" type="text" name="lastName" :value="user?.lastName" :disabled="isLoading">
                </label>
            </div>

            <div class="mb-3">
                <label class="form-label fw-bold w-50">Avatar:</label><br>
                <ImageUploadField name="avatar" :value="user?.avatarUrl" :disabled="isLoading"/>
            </div>

            <div v-if="user?.role === 'teacher'" class="mb-3 w-50">
                <label class="form-label fw-bold w-50">Scholen:</label>
                <SchoolMultiSelect name="schools" :disabled="isLoading"/>
            </div>

            <hr>

            <div class="mt-3">
                <SubmitButton class="w-100 centered" :is-loading="isLoading">Opslaan</SubmitButton>
            </div>
        </BsCard>
    </SimpleForm>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import SimpleForm from '@/components/form/SimpleForm.vue';
import BsCard from '@/components/bootstrap/BsCard.vue';
import useCurrentUser from '@/composeables/useCurrentUser';
import ImageUploadField from '@/components/form/ImageUploadField.vue';
import SchoolMultiSelect from '@/components/form/SchoolMultiSelect.vue';
import SubmitButton from '@/components/form/SubmitButton.vue';
import useModal from '@/composeables/useModal';
import {IcaltResponse} from '@/types/IcaltResponse';
import {CurrentUserSchema} from '@/models/CurrentUser';

export default defineComponent({
    name: 'ProfileView',
    components: {SubmitButton, SchoolMultiSelect, ImageUploadField, SimpleForm, BsCard},
    setup() {
        const isLoading = ref(false);

        useCurrentUser().load();

        return {
            user: useCurrentUser().user,
            isLoading,
            onSubmit() {
                isLoading.value = true;
            },
            onResponse(response: IcaltResponse) {
                isLoading.value = false;
                useCurrentUser().set(CurrentUserSchema.parse(response.data));
            },
            changePassword() {
                useModal().open('ResetPasswordMessage');
            }
        }
    }
})
</script>
