import {z} from 'zod';

export enum Role {
    TEACHER = 'teacher',
    OBSERVER = 'observer'
}

export const UserSchema = z.object({
    id: z.number(),
    name: z.string(),
    role: z.enum(['teacher', 'observer']),
    roleLabel: z.string(),
    avatarUrl: z.string(),
    gender: z.enum(['male', 'female']).optional(),
    code: z.string()
});

export type User = z.infer<typeof UserSchema>;
