<template>
    <div v-if="observation" :class="{'observation-closed': observation.isClosed}">
        <SimpleForm id="observationForm" :target="`wp-json/api/observation/${observationId}/set-info/`" alerts="html" @response="onResponse">
            <AssessmentFormFields :assessment="observation"/>

            <BsCard title="Docent Informatie">
                <div class="form-check mb-3">
                    Is er een tweede persoon in de geobserveerde klas (die onderwijskundige taken uitvoert)?<br>
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="second_teacher" value="1" :checked="observation.secondTeacher === true" :disabled="observation.isClosed">
                        Ja
                    </label><br>
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="second_teacher" value="" :checked="observation.secondTeacher === false" :disabled="observation.isClosed">
                        Nee
                    </label><br>
                </div>

                <div class="form-check mb-3">
                    Welk percentage van de lestijd was deze persoon aanwezig<br>

                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="second_teacher_presence_percent" value="0-25" :checked="observation.secondTeacherPercentage === '0-25'" :disabled="observation.isClosed">
                        &lt;25%
                    </label><br>
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="second_teacher_presence_percent" value="25-50" :checked="observation.secondTeacherPercentage === '25-50'" :disabled="observation.isClosed">
                        25% ~ 50%
                    </label><br>
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="second_teacher_presence_percent" value="50-75" :checked="observation.secondTeacherPercentage === '50-75'" :disabled="observation.isClosed">
                        50% ~ 75%
                    </label><br>
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="second_teacher_presence_percent" value="75-100" :checked="observation.secondTeacherPercentage === '75-100'" :disabled="observation.isClosed">
                        75% ~ 100%
                    </label>
                </div>

                <button v-if="!observation.isClosed" type="submit" class="btn btn-primary w-100">Opslaan</button>
            </BsCard>
        </SimpleForm>
    </div>
    <ProgressSpinner v-else/>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import useObservation from '@/composeables/useObservation';
import {IcaltResponse} from '@/types/IcaltResponse';
import SimpleForm from '@/components/form/SimpleForm.vue';
import BsCard from '@/components/bootstrap/BsCard.vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import useTeacher from '@/composeables/useTeacher';
import AssessmentFormFields from '@/components/form/AssessmentFormFields.vue';
import DetailedObservation from '@/models/DetailedObservation';

export default defineComponent({
    name: 'GeneralObservationInfoForm',
    components: {AssessmentFormFields, ProgressSpinner, BsCard, SimpleForm},
    props: {
        observationId: {type: Number, required: true}
    },
    setup(props) {
        const errors = ref<{[key: string]: string}>({});

        useObservation().load(props.observationId).then((observation) => {
            if (observation) {
                useTeacher().load(observation.teacherId);
            }
        });

        return {
            observation: useObservation().observation,
            onResponse(response: IcaltResponse) {
                if (response.success) {
                    useObservation().setObservation(new DetailedObservation(response.data));
                    window.scrollTo(0, 0);
                    errors.value = {};
                } else {
                    response.errors.forEach((error) => {
                        const err = error.split(':');
                        if (err.length === 2) {
                            errors.value[err[0]] = err[1];
                        }
                    });
                }
            }
        }
    }
})
</script>
