<template>
    <header>
        <RouterLink :to="{name: Routes.Home}" class="text-decoration-none">
            <div class="logo">ICALT</div>
        </RouterLink>

        <UserAlerts v-if="user?.role === 'teacher'" class="ms-auto"/>

        <UserMenu :user="user"/>
    </header>
</template>

<script lang="ts">

import {defineComponent} from 'vue';
import {Routes} from '@/router';
import useCurrentUser from '@/composeables/useCurrentUser';
import UserAlerts from '@/components/user/UserAlerts.vue';
import UserMenu from '@/components/user/UserMenu.vue';

export default defineComponent({
    name: 'MenuBar',
    components: {UserMenu, UserAlerts},
    async setup() {
        return {
            user: useCurrentUser().user,
            Routes
        }
    }
})
</script>
