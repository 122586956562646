import XhrResource from '@/helpers/XhrResource';
import {TeacherSchema} from '@/models/Teacher';

const resource = new XhrResource((data: unknown[]) => {
    return data.map((item) => {
        return TeacherSchema.parse(item);
    });
});

export default () => ({
    load() {
        return resource.load(`wp-json/api/teachers/`);
    },
    clear: () => resource.clear(),
    teachers: resource.get()
});
