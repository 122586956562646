import XhrResource from '@/helpers/XhrResource';
import {UserSchema} from '@/models/User';

const resource = new XhrResource((data: unknown[]) => {
    return data.map((item) => UserSchema.parse(item));
});

export default () => ({
    load() {
        return resource.load(`wp-json/api/observers/`);
    },
    clear: () => resource.clear(),
    observers: resource.get()
});
