import XhrResource from '@/helpers/XhrResource';
import {CurrentUser, CurrentUserSchema} from '@/models/CurrentUser';
import {computed} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {Routes} from '@/router';

const resource = new XhrResource((data) => {
    return (data) ? CurrentUserSchema.parse(data) : undefined;
});

export default () => ({
    user: computed(() => {
        const user = resource.get().value;

        if (!user && useRoute()?.name !== Routes.Login) {
            useRouter()?.push({name: Routes.Login});
        }

        return user;
    }),
    load() {
        return resource.load('wp-json/api/me/');
    },
    clear() {
        return resource.clear();
    },
    set(user: CurrentUser) {
        resource.set(user);
    }
});
