import XhrResource from '@/helpers/XhrResource';
import Evaluation from '@/models/Evaluation';

const resource = new XhrResource((data: any[]) => data.map((item) => new Evaluation(item)));

export default () => {
    return {
        load(teacherId: number){
            return resource.load(`wp-json/api/teacher/${teacherId}/evaluations/`);
        },
        clear: () => resource.clear(),
        evaluations: resource.get()
    };
}
