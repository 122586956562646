<template>
    <div class="score-wrap" :class="scoreClass">
        {{ score || '?' }}
        <span v-if="scoreDiff" class="score-difference">
            {{ (scoreDiff > 0) ? '+' : '' }}{{ scoreDiff }}
        </span>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'ScoreProgressCell',
    props: {
        score: {type: Number, default: undefined},
        nextScore: {type: Number, default: undefined}
    },
    setup(props) {
        const getScoreClass = (scoreDiff: number|undefined) => {
            if (scoreDiff === undefined) {
                return 'score-wrap-initial';
            } else if (scoreDiff > 0) {
                return 'score-wrap-positive';
            } else if (scoreDiff < 0) {
                return 'score-wrap-negative';
            }

            return 'score-wrap-neutral';
        }

        const scoreDiff = (props.score !== undefined && props.nextScore !== undefined) ? props.score - props.nextScore : undefined;

        return {
            scoreDiff,
            scoreClass: getScoreClass(scoreDiff)
        }
    }
})
</script>
