<template>
    <table v-if="data && data.observations.length > 1" class="table observation-progress-table">
        <tr class="bg-light-blue">
            <th/>
            <th v-for="(observation, i) in data.observations" :key="i" class="observation-date">
                <RouterLink class="nav-link" :to="{name: 'ObservationResults', params: {observationId: observation.id}}">
                    <NiceDate :date="observation.date"/>
                </RouterLink>
            </th>
        </tr>

        <template v-for="(statementGroup, g) in data.statementGroups" :key="g">
            <tr class="group-header" :class="`group__item${g}`">
                <td :colspan="data.observations.length + 1">{{ statementGroup.group.name }}</td>
            </tr>

            <tr v-for="(statement, s) in statementGroup.statements" :key="s">
                <td>{{ statement.question }}</td>

                <td v-for="(observation, o) in data.observations" :key="o">
                    <ScoreProgressCell :score="observation.scores[statement.id]" :next-score="data.observations[o + 1]?.scores[statement.id]"/>
                </td>
            </tr>
        </template>
    </table>
    <div v-else-if="data" class="row__notification">
        U moet toegang tot ten minste 2 observaties hebben om de vergelijkingstabel te zien.
    </div>
    <ProgressSpinner v-else/>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import {fetchGet} from '@/helpers/fetchHelper';
import {ObservationProgress, ObservationProgressSchema} from '@/models/ObservationProgress';
import ScoreProgressCell from '@/components/observation/ScoreProgressCell.vue';
import ProgressSpinner from '@/App.vue';
import NiceDate from '@/components/standalone/NiceDate.vue';

export default defineComponent({
    name: 'ObservationProgressTable',
    components: {NiceDate, ProgressSpinner, ScoreProgressCell},
    props: {
        teacherId: {type: Number, required: true}
    },
    setup(props) {
        const data = ref<ObservationProgress>();

        fetchGet(`wp-json/api/teacher/${props.teacherId}/progress-table/`).then((response) => {
            if (response.errors?.length) {
                console.error(response);
            } else {
                data.value = ObservationProgressSchema.parse(response.data);
            }
        });

        return {
            data
        }
    }
})
</script>
