import XhrResource from '@/helpers/XhrResource';
import {z} from 'zod';

const EducationInformationSchema = z.object({
    timeframes: z.record(z.string()),
    educationLevels: z.record(z.string())
});

const resource = new XhrResource((v: unknown) => EducationInformationSchema.parse(v));

export default () => ({
    load() {
        return resource.load(`wp-json/api/education-information/`);
    },
    info: resource.get(),
});
