import {createRouter, createWebHashHistory, RouteLocationNormalized, RouteRecordRaw} from 'vue-router';
import {Role} from '@/models/User';
import MainView from '@/views/MainView.vue';
import TeacherList from '@/views/observer/TeacherList.vue';
import ObservationList from '@/views/teacher/ObservationList.vue';
import EvaluationView from '@/views/evaluation/EvaluationView.vue';
import TeacherProgress from '@/views/teacher/TeacherProgress.vue';
import ObservationView from '@/views/observation/ObservationView.vue';
import ObservationGeneral from '@/views/observation/ObservationGeneral.vue';
import ObservationForm from '@/views/observation/ObservationForm.vue';
import ObservationResults from '@/views/observation/ObservationResults.vue';
import LoginScreen from '@/views/LoginScreen.vue';
import useCurrentUser from '@/composeables/useCurrentUser';
import StudentPerceptionForm from '@/views/perception/StudentPerceptionForm.vue';
import EvaluationGeneral from '@/views/evaluation/EvaluationGeneral.vue';
import EvaluationList from '@/views/teacher/EvaluationList.vue';
import TeacherView from '@/views/teacher/TeacherView.vue';
import ProfileView from '@/views/profile/ProfileView.vue';
import ObserverList from '@/views/teacher/ObserverList.vue';
import ObservationProgressTable from '@/components/observation/ObservationProgressTable.vue';

export enum Routes {
    ProfileView = 'ProfileView',
    ObserverList = 'ObserverList',
    TeacherList = 'TeacherList',
    TeacherDetail = 'TeacherDetail',
    // StudentPerceptionGeneralInfo = 'StudentPerceptionGeneralInfo',
    // StudentPerceptionShare = 'StudentPerceptionShare',
    StudentPerceptionForm = 'StudentPerceptionForm',
    ObservationGeneralInfo = 'ObservationGeneralInfo',
    ObservationForm = 'ObservationForm',
    ObservationResults = 'ObservationResults',
    EvaluationGeneralInfo = 'EvaluationGeneralInfo',
    TeacherProgressOverview = 'TeacherProgressOverview',
    // StudentPerceptionList = 'StudentPerceptionList',
    EvaluationList = 'EvaluationList',
    Login = 'Login',
    Home = 'Home',
}

const paramToInt = (input: string|string[]) => {
    if (Array.isArray(input)) {
        return input.map((item) => parseInt(item, 10))
    }

    return parseInt(input, 10);
}

const asNumbers = (loc: RouteLocationNormalized) => {
    const obj: {[key: string]: number|number[]} = {};

    for (const paramsKey in loc.params) {
        obj[paramsKey] = paramToInt(loc.params[paramsKey]);
    }

    return obj;
}

declare module 'vue-router' {
    interface RouteMeta {
        // When true, user does not need to be logged in to visit this route
        isPublic?: boolean
    }
}

export const perceptionSharePath = '/student/';

const routes: Array<RouteRecordRaw&{ name?: Routes }> = [
    {path: '/', name: Routes.Home, component: MainView, children: [
        {path: '/me', name: Routes.ProfileView, component: ProfileView},

        // Observer View
        {path: '/teachers', name: Routes.TeacherList, component: TeacherList},
        {path: '/observers', name: Routes.ObserverList, component: ObserverList},

        // Teacher View
        {path: '/teachers/:teacherId', component: TeacherView, props: asNumbers, children: [
            {path: '', name: Routes.TeacherDetail, props: asNumbers, component: ObservationList},
            // {path: '/teachers/:teacherId/student-perceptions', name: Routes.StudentPerceptionList, props: asNumbers, component: StudentPerceptionList},
            {path: '/teachers/:teacherId/evaluations', name: Routes.EvaluationList, props: asNumbers, component: EvaluationList},
            {path: '/teachers/:teacherId/progress', name: Routes.TeacherProgressOverview, props: asNumbers, component: TeacherProgress},
            {path: '/teachers/:teacherId/table', name: 'TeacherProgressTable', props: asNumbers, component: ObservationProgressTable},
        ]},

        // Perception View
        // {path: '/perceptions/:perceptionId', component: StudentPerceptionView, props: asNumbers, children: [
        //     {path: '', name: Routes.StudentPerceptionGeneralInfo, props: asNumbers, component: StudentPerceptionGeneral},
        //     {path: '/perceptions/:perceptionId/share', name: Routes.StudentPerceptionShare, props: asNumbers, component: StudentPerceptionShare}
        // ]},

        // Observation View
        {path: '/observations/:observationId', component: ObservationView, props: asNumbers, children: [
            {path: '', name: Routes.ObservationGeneralInfo, props: asNumbers, component: ObservationGeneral},
            {path: '/observations/:observationId/form/:groupId', name: Routes.ObservationForm, props: asNumbers, component: ObservationForm},
            {path: '/observations/:observationId/results', name: Routes.ObservationResults, props: asNumbers, component: ObservationResults}
        ]},

        // Evaluation View
        {path: '/evaluations/:evaluationId', component: EvaluationView, props: asNumbers, children: [
            {path: '', name: Routes.EvaluationGeneralInfo, props: asNumbers, component: EvaluationGeneral}
        ]},
    ]},

    // Standalone
    {path: `${perceptionSharePath}:shareKey`, name: Routes.StudentPerceptionForm, props: true, component: StudentPerceptionForm, meta: {isPublic: true}},
    {path: '/login', name: Routes.Login, component: LoginScreen, meta: {isPublic: true}}
]

const router = createRouter({history: createWebHashHistory(), routes});

router.beforeEach(async (to, from , next) => {
    const user = await useCurrentUser().load();

    if (!user && !to.meta.isPublic) {
        next({name: Routes.Login});
        return;
    } else if (user && to.name === Routes.Login) {
        next({name: Routes.Home});
        return;
    } else if (user && to.name === Routes.Home && user.role === Role.TEACHER) {
        next({name: Routes.TeacherDetail, params: {teacherId: user.id}});
        return;
    } else if (user && to.name === Routes.Home && user.role === Role.OBSERVER) {
        next({name: Routes.TeacherList});
        return;
    }

    next();
});

export default router;
