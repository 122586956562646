<template>
    <span :title="new Intl.DateTimeFormat(lang, {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'}).format(date)">
        {{ date.toLocaleDateString() }}
    </span>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'NiceDate',
    props: {
        date: {type: Date, required: true}
    },
    setup() {
        return {
            lang: navigator.languages[0] || 'en-US'
        }
    }
})
</script>
