import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "layout__wrapper" }
const _hoisted_2 = { class: "row__header" }
const _hoisted_3 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_4 = { class: "text-primary fw-bold" }
const _hoisted_5 = { class: "row__tabs" }
const _hoisted_6 = { class: "nav__tabs" }
const _hoisted_7 = { class: "nav__tabs__tab" }
const _hoisted_8 = { class: "nav__tabs__tab" }
const _hoisted_9 = { class: "nav__tabs__tab" }
const _hoisted_10 = { class: "nav__tabs__tab" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.user?.role === _ctx.Role.OBSERVER)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_RouterLink, {
              to: {name: _ctx.Routes.TeacherList},
              class: "route-back"
            }, {
              default: _withCtx(() => [
                _createTextVNode("« Naar alle docenten")
              ]),
              _: 1
            }, 8, ["to"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.teacher?.name), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("ul", _hoisted_6, [
        _createElementVNode("li", _hoisted_7, [
          _createVNode(_component_RouterLink, {
            class: "nav-link",
            to: {name: _ctx.Routes.TeacherDetail}
          }, {
            default: _withCtx(() => [
              _createTextVNode("Observaties")
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("li", _hoisted_8, [
          _createVNode(_component_RouterLink, {
            class: "nav-link",
            to: {name: _ctx.Routes.EvaluationList}
          }, {
            default: _withCtx(() => [
              _createTextVNode("Evaluaties")
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("li", _hoisted_9, [
          _createVNode(_component_RouterLink, {
            class: "nav-link",
            to: {name: _ctx.Routes.TeacherProgressOverview}
          }, {
            default: _withCtx(() => [
              _createTextVNode("Voortgang")
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("li", _hoisted_10, [
          _createVNode(_component_RouterLink, {
            class: "nav-link",
            to: {name: 'TeacherProgressTable'}
          }, {
            default: _withCtx(() => [
              _createTextVNode("Tabel")
            ]),
            _: 1
          })
        ])
      ])
    ]),
    (_openBlock(), _createBlock(_component_RouterView, {
      key: _ctx.$route.fullPath
    }))
  ]))
}