import {UserSchema} from '@/models/User';
import {z} from 'zod';

export const TeacherSchema = UserSchema.extend({
    activeObservationId: z.number().optional(),
    lastObservationDate: z.string(),
    schools: z.record(z.string()),
    status: z.enum(['requested', 'allowed', 'disallowed'])
});

export type Teacher = z.infer<typeof TeacherSchema>;
