import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flat-chart-graph bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LineChart = _resolveComponent("LineChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LineChart, _normalizeProps(_guardReactiveProps(_ctx.lineChartProps)), null, 16)
  ]))
}