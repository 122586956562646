import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FetchButton = _resolveComponent("FetchButton")!

  return (_openBlock(), _createBlock(_component_FetchButton, {
    target: `wp-json/api/teacher/${_ctx.teacherId}/${_ctx.type}s/create`,
    method: "POST",
    onResponse: _ctx.onCreate
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["target", "onResponse"]))
}