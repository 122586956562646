<template>
    <div class="layout__wrapper">
        <div class="row__header">
            <div v-if="user?.role === Role.OBSERVER" class="mb-3">
                <RouterLink :to="{name: Routes.TeacherList}" class="route-back">« Naar alle docenten</RouterLink>
            </div>
            <h1 class="text-primary fw-bold">{{ teacher?.name }}</h1>
        </div>

        <div class="row__tabs">
            <ul class="nav__tabs">
                <li class="nav__tabs__tab">
                    <RouterLink class="nav-link" :to="{name: Routes.TeacherDetail}">Observaties</RouterLink>
                </li>
                <!--                <li class="nav__tabs__tab">-->
                <!--                    <RouterLink class="nav-link" :to="{name: Routes.StudentPerceptionList}">Leerlingpercepties</RouterLink>-->
                <!--                </li>-->
                <li class="nav__tabs__tab">
                    <RouterLink class="nav-link" :to="{name: Routes.EvaluationList}">Evaluaties</RouterLink>
                </li>
                <li class="nav__tabs__tab">
                    <RouterLink class="nav-link" :to="{name: Routes.TeacherProgressOverview}">Voortgang</RouterLink>
                </li>
                <li class="nav__tabs__tab">
                    <RouterLink class="nav-link" :to="{name: 'TeacherProgressTable'}">Tabel</RouterLink>
                </li>
            </ul>
        </div>

        <RouterView :key="$route.fullPath"/>
    </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import {Routes} from '@/router';
import useTeacher from '@/composeables/useTeacher';
import useCurrentUser from '@/composeables/useCurrentUser';
import {Role} from '@/models/User';
import {useRouter} from 'vue-router';
import {IcaltResponse} from '@/types/IcaltResponse';

export default defineComponent({
    name: 'TeacherView',
    props: {
        teacherId: {required: true, type: Number}
    },
    async setup(props) {
        const router = useRouter();
        const isBusy = ref(false);
        await useTeacher().load(props.teacherId);

        return {
            user: useCurrentUser().user,
            teacher: useTeacher().teacher,
            isBusy,
            onSubmit() {
                isBusy.value = true;
            },
            onResponse(response: IcaltResponse) {
                if (response.success) {
                    router.push({name: Routes.ObservationGeneralInfo, params: {observationId: Number(response.data)}});
                } else {
                    window.alert(response.errors.join(', '));
                    isBusy.value = false;
                }
            },
            Routes,
            Role
        }
    }
})
</script>
