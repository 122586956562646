import {z} from 'zod';
import {StatementSchema} from '@/models/Statement';
import {StatementGroupSchema} from '@/models/StatementGroup';

export const ObservationProgressSchema = z.object({
    observations: z.array(z.object({
        id: z.number(),
        name: z.string(),
        date: z.coerce.date(),
        scores: z.record(z.number())
    })),
    statementGroups: z.array(z.object({
        statements: z.array(StatementSchema),
        group: StatementGroupSchema
    }))
});

export type ObservationProgress = z.infer<typeof ObservationProgressSchema>;
