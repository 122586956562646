import {isString} from '@/helpers/TypeGuard';
import Assessment from '@/models/Assessment';

export default class Evaluation extends Assessment {
    public readonly content: string;

    constructor(data: Record<string, unknown>) {
        super(data, 'evaluation');
        this.content = isString(data, 'content');
    }
}
