<template>
    <RouterLink v-if="assessment.status === 'allowed'" :to="getRoute(assessment)">
        {{ (user?.id === assessment.authorId && !assessment.isClosed) ? 'Ga verder' : 'Bekijk' }}
    </RouterLink>
    <FetchButton v-else-if="assessment.status === 'disallowed'" method="post" :target="`wp-json/api/${assessment.type}/${assessment.id}/request-access`" class="btn btn-primary" @response="onResponse">
        Vraag Toegang
    </FetchButton>
    <div v-else-if="assessment.status === 'requested'">
        Toegang is gevraagd. De docent heeft nog niet gereageerd.
    </div>
</template>

<script lang="ts">
import Observation from '@/models/Observation';
import {defineComponent, PropType} from 'vue';
import {Routes} from '@/router';
import useObservations from '@/composeables/useObservations';
import useCurrentUser from '@/composeables/useCurrentUser';
import {IcaltResponse} from '@/types/IcaltResponse';
import FetchButton from '@/components/form/FetchButton.vue';
import Assessment from '@/models/Assessment';
import {isObject} from '@/helpers/objectHelper';

export default defineComponent({
    name: 'AccessStatusButton',
    components: {FetchButton},
    props: {
        assessment: {type: Object as PropType<Assessment>, required: true}
    },
    setup() {
        return {
            user: useCurrentUser().user,
            onResponse(response: IcaltResponse) {
                if (response.success && isObject(response.data)) {
                    useObservations().setEntry(new Observation(response.data));
                }
            },
            getRoute(item: Assessment) {
                if (item.type === 'observation') {
                    return {name: Routes.ObservationGeneralInfo, params: {observationId: item.id}};
                }

                if (item.type === 'evaluation') {
                    return {name: Routes.EvaluationGeneralInfo, params: {evaluationId: item.id}};
                }

                // if (item.type === 'student-perception') {
                //     return {name: Routes.StudentPerceptionGeneralInfo, params: {perceptionId: item.id}};
                // }

                throw new Error(`Unexpected assessement type ${item.type}`);
            },
            Routes
        }
    }
})
</script>
