<template>
    <div class="flat-chart-graph bg-white">
        <LineChart v-bind="lineChartProps"/>
    </div>
</template>

<script lang='ts'>
import {computed, defineComponent, PropType, ref} from 'vue';
import {LineChart, useLineChart} from 'vue-chart-3';
import {Chart, ChartData, ChartOptions, registerables, ScatterDataPoint} from 'chart.js';
import Observation from '@/models/Observation';
import Perception from '@/models/Perception';
import Evaluation from '@/models/Evaluation';

const MS_PER_DAY = 86400000;
Chart.register(...registerables);

export default defineComponent({
    name: 'FlatGraph',
    components: {LineChart},
    props: {
        color: {type: String, default: '#0f8ab2'},
        observations: {type: Object as PropType<Observation[]|Perception[]|Evaluation[]>, required: true}
    },
    setup(props) {
        // Look at this graaaph
        const dataValues = ref<ScatterDataPoint[]>([]);
        const dataLabels = ref<string[]>([]);
        const formattedDates: {[key:number]: string} = {};

        const observations = Object.freeze([...props.observations].reverse());
        const firstDate = new Date(observations[0].timestamp);

        for (const observation of observations) {
            formattedDates[observation.timestamp] = observation.date;
            dataValues.value.push({x: observation.timestamp , y: 0});
            dataLabels.value.push(observation.date);
        }

        const chartData = computed<ChartData<'line'>>(() => ({
            labels: dataLabels.value,
            datasets: [{
                data: dataValues.value,
                backgroundColor: [props.color],
                borderColor: props.color,
                pointBorderColor: ['white'],
                normalized: true,
                pointRadius: (context) => {
                    return (context.dataIndex === observations.length - 1) ? 12 : 6;
                },
                pointHoverRadius: (context) => {
                    return (context.dataIndex === observations.length - 1) ? 14 : 7;
                },
            }],
        }));

        const options = computed<ChartOptions<'line'>>(() => ({
            scales: {
                x: {
                    axis: 'x',
                    type: 'linear',
                    position: 'left',
                    min: firstDate.valueOf(),
                    ticks: {
                        stepSize: MS_PER_DAY,
                        callback: (value) => {
                            return formattedDates[Number(value)];
                        }
                    },
                    grid: {
                        display: false
                    }
                },
                y: {
                    type: 'linear',
                    position: 'left',
                    grid: {
                        color: '#66b6cf'
                    },
                    min: 0,
                    ticks: {
                        stepSize: 1
                    }
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Observaties in de tijd',
                },
                tooltip: {
                    callbacks: {
                        title(tooltipItem) {
                            return formattedDates[tooltipItem[0].parsed.x];
                        },
                        label(tooltipItem) {
                            return `[Score: ${tooltipItem.parsed.y}] ${observations[tooltipItem.dataIndex].name}`;
                        }
                    }
                }
            },
            parsing: {
                xAxisKey: 'x',
                yAxisKey: 'y'
            }
        }));

        const {lineChartProps, lineChartRef} = useLineChart({chartData, options});

        return {
            chartData,
            options,
            lineChartRef,
            lineChartProps,
        };
    },
});
</script>
