import Observation from '@/models/Observation';
import XhrResource from '@/helpers/XhrResource';

const resource = new XhrResource((data: any[]) => data.map((item) => new Observation(item)));

export default () => {
    return {
        load(teacherId: number) {
            return resource.load(`wp-json/api/teacher/${teacherId}/observations/`);
        },
        observations: resource.get(),
        clear: () => resource.clear(),
        setEntry(observation: Observation) {
            const dataRef = resource.get();

            if (dataRef.value) {
                const index = dataRef.value.findIndex((o) => o.id === observation.id);
                dataRef.value[index] = observation;
            }
        }
    };
}
