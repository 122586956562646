<template>
    <template v-if="statementGroups">
        <div class="layout__wrapper" :class="{'observation-closed': observation?.isClosed}">
            <div class="row__content row--radius py-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-md-4 px-0 border-end">
                            <ul class="list-unstyled observation-form__tabs">
                                <li v-for="(group, id, index) in statementGroups" :key="group.id">
                                    <RouterLink :to="{name: Routes.ObservationForm, params: {observationId, groupId: group.id}}" class="group__item" :class="`group__item${index}`">
                                        <span class="group__item__name">
                                            {{ group.name }}
                                        </span>&nbsp;

                                        <span v-if="observation && isGroupComplete(observation, group.id)" class="group__item__counter group__item__counter--success">
                                            &#10004;
                                        </span>
                                        <span v-else class="group__item__counter">
                                            {{ unansweredGroupQuestions(observation, group.id) }}
                                        </span>
                                    </RouterLink>
                                </li>
                            </ul>
                        </div>

                        <div class="col-12 col-md-8 px-0">
                            <div v-if="observation?.statements[groupId]" class="statements">
                                <div v-for="statement in observation?.statements[groupId]" :key="statement.id" class="statements__item">
                                    <div class="statements__item__indicators">
                                        <h6 class="fw-bold mb-3">{{ statement.question }}</h6>
                                        <IndicationSelect :statement="statement" :observation-id="observationId" :disabled="observation?.isClosed"/>
                                    </div>

                                    <div class="statements__item__score">
                                        <StatementSelect :statement="statement" :observation-id="observationId" :disabled="observation?.isClosed"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import useObservation from '@/composeables/useObservation';
import {Routes} from '@/router';
import useStatementGroups from '@/composeables/useStatementGroups';
import StatementSelect from '@/components/form/StatementSelect.vue';
import IndicationSelect from '@/components/form/IndicationSelect.vue';
import DetailedObservation from '@/models/DetailedObservation';

export default defineComponent({
    name: 'ObservationForm',
    components: {StatementSelect, IndicationSelect},
    props: {
        observationId: {type: Number, required: true},
        groupId: {type: Number, required: true}
    },
    setup() {
        useStatementGroups().load();

        const observation = useObservation().observation;
        const statementGroups = useStatementGroups().statementGroups;

        return {
            observation,
            statementGroups,
            Routes,
            unansweredGroupQuestions(observation: DetailedObservation, groupId: number) {
                let length = 0;

                for (const statement of observation.statements[groupId]) {
                    if (statement.score === undefined) {
                        length++;
                    }
                }

                return length;
            },
            isGroupComplete(observation: DetailedObservation, groupId: number) {
                for (const statement of observation.statements[groupId]) {
                    if (statement.score === undefined) {
                        return false;
                    }
                }

                return true;
            }
        }
    }
})
</script>
