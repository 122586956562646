<template>
    <div class="layout__wrapper">
        <div class="row__content row--radius">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-3 bg-light-blue pt-4">
                        <h6 class="fw-bold">Observaties in de tijd</h6>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sed augue euismod, maximus turpis ut, aliquam nulla. Nunc eu orci porttitor, lacinia lectus nec, feugiat diam. Aliquam erat volutpat.</p>
                    </div>
                    <div class="col-12 col-md-9 pt-4">
                        <ChartGraph v-if="observations && observations.length" :observations="observations"/>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-3 bg-light-blue pt-5 pb-3">
                        <h6 class="fw-bold">Overzichtstabel</h6>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sed augue euismod, maximus turpis ut, aliquam nulla. Nunc eu orci porttitor, lacinia lectus nec, feugiat diam. Aliquam erat volutpat.</p>
                    </div>
                    <div class="col-12 col-md-9 pt-5 pb-3">
                        <ObservationScoreTable v-if="observation && teacher" :teacher="teacher" :observation="observation"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import useObservation from '@/composeables/useObservation';
import useObservations from '@/composeables/useObservations';
import useTeacher from '@/composeables/useTeacher';
import ObservationScoreTable from '@/components/observation/ObservationScoreTable.vue';
import ChartGraph from '@/components/charts/ChartGraph.vue';

export default defineComponent({
    name: 'ObservationResults',
    components: {ChartGraph, ObservationScoreTable},
    props: {
        observationId: {type: Number, required: true}
    },
    setup() {
        const observation = useObservation().observation;
        if (observation.value) {
            useObservations().load(observation.value.teacherId);
            useTeacher().load(observation.value.teacherId);
        }

        return {
            observation,
            teacher: useTeacher().teacher,
            observations: useObservations().observations
        }
    }
})
</script>
