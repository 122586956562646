<template>
    <img v-if="user.avatarUrl" :src="user.avatarUrl">
    <div v-else>{{ user.name[0] }}</div>
</template>

<script lang="ts">
import {User} from '@/models/User';
import {defineComponent, PropType} from 'vue';

export default defineComponent({
    name: 'UserIcon',
    props: {
        user: {type: Object as PropType<User>, required: true}
    }
})
</script>
