<template>
    <div class="row__chart">
        <FlatGraph v-if="evaluations?.length" :observations="evaluations"/>
    </div>

    <div class="row__actions border-bottom">
        <CreateItemButton :type="'evaluation'" :teacher-id="teacherId" class="btn btn-outline-primary">
            Maak nieuwe Evaluatie
        </CreateItemButton>
    </div>

    <section class="evaluation-overview">
        <table v-if="evaluations?.length" class="table table-light">
            <thead>
                <tr class="table-heading">
                    <th>Datum evaluatie</th>
                    <th>Auteur</th>
                    <th>Organisatie</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(evaluation, key) in evaluations" :key="key">
                    <td class="fw-bold text-primary">{{ evaluation.date }}</td>
                    <td>{{ evaluation.authorName }}</td>
                    <td>{{ evaluation.organisationName }}</td>
                    <td class="lookup text-end"><AccessStatusButton :assessment="evaluation"/></td>
                </tr>
            </tbody>
        </table>
        <div v-else-if="evaluations" class="row__notification">Deze docent heeft nog geen evaluaties.</div>
        <ProgressSpinner v-else/>
    </section>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import useEvaluations from '@/composeables/useEvaluations';
import {IcaltResponse} from '@/types/IcaltResponse';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import {useRouter} from 'vue-router';
import {Routes} from '@/router';
import CreateItemButton from '@/components/form/CreateItemButton.vue';
import FlatGraph from '@/components/charts/FlatGraph.vue';
import AccessStatusButton from '@/components/observation/AccessStatusButton.vue';

export default defineComponent({
    name: 'EvaluationList',
    components: {CreateItemButton, ProgressSpinner, FlatGraph, AccessStatusButton},
    props: {
        teacherId: {required: true, type: Number}
    },
    async setup(props) {
        useEvaluations().load(props.teacherId);
        const router = useRouter();

        return {
            evaluations: useEvaluations().evaluations,
            createdEvaluation(response: IcaltResponse) {
                if (response.success) {
                    router.push({name: Routes.EvaluationGeneralInfo, params: {evaluationId: Number(response.data)}});
                } else {
                    window.alert(response.errors.join(', '));
                }
            }
        }
    }
})
</script>
