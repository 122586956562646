<template>
    <div>
        <!-- The form that an observer sees-->
        <template v-if="user?.role === 'observer'">
            Vul de code van de docent in om toegang te vragen tot het profiel van de docent.
            <SimpleForm :target="`wp-json/api/teacher/${uid}/request-access/`" :alerts="'html'" :success-text="successMessage" @response="onResponse">
                <input type="text" class="user-code-input" autocomplete="off" name="teacherUid" min="10" placeholder="Docent code..." autofocus @input="inputUid">
                <button :disabled="uid.length < 10" class="btn btn-primary float-end mt-3">
                    Docent uitnodigen
                </button>
            </SimpleForm>
        </template>

        <!-- The form that a teacher sees -->
        <template v-if="user?.role === 'teacher'">
            Vul de code van de observator in om deze direct toegang te geven tot uw profiel.
            <SimpleForm :target="`wp-json/api/observer/grant-instant-access/`" :alerts="'html'" :success-text="successMessage" @response="onResponse">
                <input type="text" class="user-code-input" autocomplete="off" name="observerUid" min="10" placeholder="Observator code..." autofocus @input="inputUid">
                <button :disabled="uid.length < 10" class="btn btn-primary float-end mt-3">
                    Observator toegang geven
                </button>
            </SimpleForm>
        </template>

        <ProgressSpinner v-if="!user"/>
    </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import SimpleForm from '@/components/form/SimpleForm.vue';
import {IcaltResponse} from '@/types/IcaltResponse';
import useTeachers from '@/composeables/useTeachers';
import useCurrentUser from '@/composeables/useCurrentUser';
import ProgressSpinner from '@/App.vue';
import useObservers from '@/composeables/useObservers';

export default defineComponent({
    name: 'InvitePersonForm',
    components: {ProgressSpinner, SimpleForm},
    setup() {
        const uid = ref('');
        const successMessage = ref('');
        useCurrentUser().load();

        return {
            uid,
            successMessage,
            user: useCurrentUser().user,
            inputUid(e: Event & {target: HTMLInputElement}) {
                uid.value = e.target.value;
            },
            onResponse(response: IcaltResponse, form: HTMLFormElement) {
                if (response.success) {
                    const composeable = (useCurrentUser().user.value?.role === 'observer') ? useTeachers() : useObservers();
                    composeable.clear();
                    composeable.load();
                    successMessage.value = String(response.data);
                    uid.value = '';
                    form.reset();
                }
            }
        }
    }
})
</script>
