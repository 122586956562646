<template>
    <input ref="images" type="file" :disabled="disabled" :name="name" @change="handleImageUpload"><br>

    <template v-if="imageUrl || value">
        <br>
        <img :src="imageUrl || value" class="img-thumbnail img-upload-preview">
    </template>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';

export default defineComponent({
    name: 'ImageUploadField',
    props: {
        value: {type: String, default: ''},
        name: {type: String, required: true},
        disabled: {type: Boolean, default: false},
    },
    emits: ['change'],
    setup() {
        const imageInput = ref<FileList|File[]>([]);
        const imageUrl = ref('');

        return {
            imageInput,
            imageUrl,
            handleImageUpload(e: Event & {target: HTMLInputElement & {files: FileList}}) {
                if (e.target.files.length === 1) {
                    imageUrl.value = URL.createObjectURL(e.target.files[0]);
                }
            }
        };
    }
})
</script>
