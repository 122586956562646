<template>
    <div>
        <template v-if="message">
            <div v-html="message"></div>
            <button class="btn btn-primary text-white centered" @click="closeModal">Ok</button>
        </template>
        <ProgressSpinner v-else/>
    </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import useModal from '@/composeables/useModal';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import { fetchGet } from '@/helpers/fetchHelper';

export default defineComponent({
    name: 'ResetPasswordMessage',
    components: {ProgressSpinner},
    setup() {
        const message = ref('');

        fetchGet('wp-json/api/me/request-reset-password/').then((response) => {
            if (response.errors.length) {
                message.value = response.errors.join('<br>');
            } else {
                message.value = String(response.data);
            }
        });

        return {
            message,
            closeModal() {
                useModal().close()
            }
        }
    }
})
</script>
