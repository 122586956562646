import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    disabled: _ctx.disabled || _ctx.isBusy,
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["prevent"]))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}