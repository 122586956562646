import {computed, ref} from 'vue';

const componentStack = ref<string[]>([]);

export default () => ({
    componentName: computed(() => (componentStack.value.length) ? componentStack.value[componentStack.value.length -1] : undefined),
    componentStack: computed(() => componentStack.value),
    canGoBack: computed(() => (componentStack.value.length > 1)),
    open: (componentToOpen: string) => {
        componentStack.value.push(componentToOpen);
    },
    goBack: () => {
        componentStack.value.pop();
    },
    close: () => {
        componentStack.value = [];
    },
})