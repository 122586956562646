import {Statement, StatementSchema} from '@/models/Statement';
import Observation from '@/models/Observation';
import {isBooleanOrUndefined, isNumber, isNumberOrUndefined, isString} from '@/helpers/TypeGuard';
import {mapObject} from '@/helpers/objectHelper';
import {z} from 'zod';

export default class DetailedObservation extends Observation {
    public readonly schoolId: number;
    public readonly timeframeId: number|undefined;
    public readonly lowestEducationLevel: string;
    public readonly highestEducationLevel: string;
    public readonly isCombinedClass: boolean|undefined;
    public readonly observedSchoolSubject: string;
    public readonly schoolClassName: string;
    public readonly amountOfStudents: number;
    public readonly amountOfStudentsWithBackpack: number;
    public readonly developmentZoneFrom: number|undefined;
    public readonly developmentZoneTo: number|undefined;
    public readonly secondTeacher: boolean|undefined;
    public readonly secondTeacherPercentage: string;
    public readonly statements: {[key: string]: Statement[]};

    constructor(data: any) {
        super(data);

        this.schoolId = isNumber(data, 'schoolId');
        this.timeframeId = isNumberOrUndefined(data, 'timeframeId');
        this.lowestEducationLevel = isString(data, 'lowestEducationLevel');
        this.highestEducationLevel = isString(data, 'highestEducationLevel');
        this.isCombinedClass = isBooleanOrUndefined(data, 'isCombinedClass');
        this.observedSchoolSubject = isString(data, 'observedSchoolSubject');
        this.schoolClassName = isString(data, 'schoolClassName');
        this.amountOfStudents = isNumber(data, 'amountOfStudents');
        this.amountOfStudentsWithBackpack = isNumber(data, 'amountOfStudentsWithBackpack');
        this.developmentZoneFrom = isNumberOrUndefined(data, 'developmentZoneFrom');
        this.developmentZoneTo = isNumberOrUndefined(data, 'developmentZoneTo');
        this.secondTeacher = isBooleanOrUndefined(data, 'secondTeacher');
        this.secondTeacherPercentage = isString(data, 'secondTeacherPercentage');
        this.statements = mapObject(data.statements, (s) => z.array(StatementSchema).parse(s));
    }
}
