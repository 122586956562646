<template>
    <ProgressSpinner v-if="isLoading"/>
    <template v-else>
        <ul>
            <li v-for="itemId in selectedValues" :key="itemId">
                {{ schools[itemId] }}&nbsp;
                <button type="button" class="btn btn-danger" :disabled="disabled" @click="() => removeItem(itemId)">
                    X
                </button>
            </li>
        </ul>

        <select v-if="Object.keys(filteredSchools).length" class="form-select" aria-label="Voeg een item toe..." :disabled="disabled" @change="addItem">
            <option/>
            <option v-for="(fLabel, fId) in filteredSchools" :key="fId" :value="fId">{{ fLabel }}</option>
        </select>

        <input type="hidden" :value="selectedValues.join(',')" :name="name">
    </template>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from 'vue';
import useSchools from '@/composeables/useSchools';
import useCurrentUser from '@/composeables/useCurrentUser';
import ProgressSpinner from '@/App.vue';

export default defineComponent({
    name: 'ItemMultiSelect',
    components: {ProgressSpinner},
    props: {
        name: {type: String, required: true},
        disabled: {type: Boolean, default: false}
    },
    setup() {
        const selectedValues = ref<number[]>([]);
        const isLoading = ref(true);
        const schools = useSchools().schools;

        Promise.all([useSchools().load(), useCurrentUser().load()]).then(() => {
            const itemIds = useCurrentUser().user.value?.schoolIds;
            if (itemIds) {
                selectedValues.value.push(...itemIds);
                isLoading.value = false;
            }
        });

        return {
            schools,
            filteredSchools: computed(() => {
                const items = schools.value || {};
                const filteredItems: {[key: string]: string} = {};

                for (const id in items) {
                    if (!selectedValues.value.includes(Number(id))) {
                        filteredItems[id] = items[id];
                    }
                }

                return filteredItems;
            }),
            selectedValues,
            isLoading,
            addItem(e: Event & {target: HTMLSelectElement}) {
                const value = parseInt(e.target.value, 10);
                if (!selectedValues.value.includes(value)) {
                    selectedValues.value.push(value);
                    e.target.selectedIndex = 0;
                }
            },
            removeItem(itemId: number) {
                const i = selectedValues.value.indexOf(itemId);
                if (i !== -1) {
                    selectedValues.value.splice(i, 1);
                }
            }
        }
    }
})
</script>
