import {UserSchema} from '@/models/User';
import {z} from 'zod';

export const AccessRequestSchema= z.object({
    id: z.number(),
    type: z.string(),
    text: z.string(),
    user: UserSchema
});

export type AccessRequest = z.infer<typeof AccessRequestSchema>;
