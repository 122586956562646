import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "list-unstyled indicator-list" }
const _hoisted_2 = ["value", "checked", "disabled"]
const _hoisted_3 = {
  ref: "button",
  class: "d-none"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleForm = _resolveComponent("SimpleForm")!

  return (_openBlock(), _createBlock(_component_SimpleForm, {
    target: `wp-json/api/observation/${_ctx.observationId}/indication/`,
    "extra-data": {statementId: _ctx.statement.id.toString()},
    onResponse: _ctx.onResponse
  }, {
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statement.indicators, (indicator) => {
          return (_openBlock(), _createElementBlock("li", {
            key: `${indicator.uid}`
          }, [
            _createElementVNode("label", null, [
              _createElementVNode("input", {
                type: "checkbox",
                name: "indicationUids[]",
                value: indicator.uid,
                checked: _ctx.isSelected(indicator.uid),
                disabled: _ctx.disabled,
                onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSelect && _ctx.onSelect(...args)))
              }, null, 40, _hoisted_2),
              _createTextVNode("   " + _toDisplayString(indicator.answer), 1)
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("button", _hoisted_3, null, 512)
    ]),
    _: 1
  }, 8, ["target", "extra-data", "onResponse"]))
}