import XhrResource from '@/helpers/XhrResource';
import DetailedPerception from '@/models/DetailedPerception';

const resource = new XhrResource((data) => data ? new DetailedPerception(data) : undefined);

export default () => {
    return {
        load(shareKey: string){
            return resource.load(`wp-json/api/student/perception/${shareKey}/`);
        },
        clear: () => resource.clear(),
        refresh: () => resource.refresh(),
        perception: resource.get()
    };
}
