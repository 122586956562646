<template>
    <RouterLink v-if="active" :to="to"><slot/></RouterLink>
    <template v-else><slot/></template>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {RouteLocationRaw} from 'vue-router';

export default defineComponent({
    name: 'ConditionalRouterLink',
    props: {
        active: {type: Boolean, required: true},
        to: {type: Object as PropType<RouteLocationRaw>, required: true}
    }
})
</script>
