<template>
    <transition name="modal">
        <div v-if="modalComponent" class="modal-mask">
            <div class="modal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button v-if="canGoBack" class="modal__button-goback float-right" @click="goBack">&#8592;</button>
                            <h3 class="modal-title"><!--{{ modalComponent }}--></h3>
                            <button class="modal__button-close float-right" @click="closeModal"/>
                        </div>

                        <div class="modal-body">
                            <component :is="mcName" v-for="(mcName, mcKey) in modalStack" v-show="(modalStack.length <= mcKey + 1)" :key="mcKey"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import useModal from '@/composeables/useModal';
import ResetPasswordMessage from '@/views/modal/ResetPasswordMessage.vue';
import InvitePersonForm from '@/views/modal/InvitePersonForm.vue';

export default defineComponent({
    name: 'GlobalModal',
    components: {ResetPasswordMessage, InvitePersonForm},
    setup() {
        return {
            modalComponent: useModal().componentName,
            modalStack: useModal().componentStack,
            canGoBack: useModal().canGoBack,
            goBack: useModal().goBack,
            closeModal: useModal().close
        };
    }
})
</script>
