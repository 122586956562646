import XhrResource from '@/helpers/XhrResource';
import Evaluation from '@/models/Evaluation';

const resource = new XhrResource((data) => new Evaluation(data));

export default () => {
    return {
        evaluation: resource.get(),
        getPromise: () => resource.getPromise(),
        load(evaluationId: number, force = false) {
          return resource.load(`wp-json/api/evaluation/${evaluationId}/`, force);
        }
    };
};
