export const isString = (object: any, key: string) => {
    const item = object[key];

    if (typeof item === 'string') {
        return item;
    }

    throw `${key} is not a string`;
}

export const isStringOrUndefined = (object: any, key: string) => {
    const item = object[key];

    if (typeof item === 'string') {
        return item;
    }

    if (!item) {
        return undefined;
    }

    throw `${key} is not a string`;
}

export const isNumber = (object: any, key: string) => {
    const item = object[key];

    if (typeof item === 'number' && !isNaN(item)) {
        return item;
    }

    throw `${key} is not a number`;
}

export const isNumberOrUndefined = (object: any, key: string) => {
    const item = object[key];

    if (!isNaN(item)) {
        if (typeof item === 'number') {
            return item;
        }

        if (!object[key]) {
            return undefined;
        }
    }

    throw `${key} is not a number`;
}


export const isBoolean = (object: any, key: string) => {
    const item = object[key];

    if (typeof item === 'boolean') {
        return item;
    }

    throw `${key} is not a boolean`;
}

export const isBooleanOrUndefined = (object: any, key: string) => {
    const item = object[key];

    if (typeof item === 'boolean') {
        return item;
    }

    if (!item) {
        return undefined;
    }

    throw `${key} is not a boolean`;
}
