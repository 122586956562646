<template>
    <template v-if="alerts === 'html' && result">
        <div v-if="result?.success === true && successText" class="alert alert-success" role="alert">
            {{ successText }}
        </div>
        <div v-else-if="result?.success === false" class="alert alert-danger" role="alert">
            <span v-for="(errorMessage, key) in result.errors" :key="key" v-html="errorMessage"/>
        </div>
    </template>

    <form :id="id" ref="form" method="post" :class="`simple-form ${formClasses}`" @submit.prevent="submit">
        <slot/>
    </form>
</template>

<script lang="ts">
import { IcaltResponse } from '@/types/IcaltResponse';
import {defineComponent, PropType, ref} from 'vue';

export default defineComponent({
    name: 'SimpleForm',
    props: {
        id: {type: String, default: undefined},
        disabled: {type: Boolean, default: false},
        formClasses: {type: String, default: ''},
        target: {type: String, required: true},
        alerts: {type: String as PropType<'html'|'window'|'off'>, default: 'off'},
        successText: {type: String, default: 'De gegevens zijn geüpdatet. Dank voor het invullen.'},
        extraData: {
            type: Object as PropType<{ [key: string]: string|Blob }>, default: () => {}
        }
    },
    emits: ['response', 'submit'],
    setup(props, context) {
        const form = ref<HTMLFormElement>();
        const result = ref<IcaltResponse>();

        return {
            form,
            result,
            submit() {
                const data = new FormData(form.value);

                Object.entries(props.extraData ?? {}).forEach(([name, value]) => {
                    data.set(name, value);
                });

                context.emit('submit', data);

                fetch(`${window.location.origin}/${props.target}`, {method: 'POST', body: data}).then((jsonResponse) => {
                    jsonResponse.json().then((response: IcaltResponse) => {
                        result.value = response;
                        context.emit('response', response, form.value);

                        if (props.alerts === 'window' && response) {
                            if (result.value?.success) {
                                window.alert(props.successText);
                            } else {
                                window.alert(response.errors.join('\r\n'));
                            }
                        }
                    });
                });
            }
        };
    }
})
</script>
