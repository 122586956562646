<template>
    <div class="page-container">
        <div class="login-container">
            <!-- Login Form -->
            <div class="login shadow">
                <label class="form-header font-weight-bold">Inloggen</label>

                <SimpleForm :target="`wp-json/api/${action}/`" :success-text="successTextLogin" alerts="html" @submit="onSubmit" @response="loginResponse">
                    <div class="mb-3">
                        <label class="label-text mt-1" for="email">E-mailadres</label>
                        <input id="email" class="form-control py-4" type="email" name="email">

                        <label class="label-text" for="password">Wachtwoord</label>
                        <input id="password" class="form-control py-4" type="password" name="password" autocomplete="off">
                    </div>

                    <div class="text-start">
                        <button type="submit" class="btn btn-primary font-weight-bold text-white" :disabled="isBusy" @click="login">Inloggen</button>
                        <button type="submit" class="btn forgot-pass" :disabled="isBusy" @click="forgotPass">Wachtwoord vergeten</button>
                    </div>
                </SimpleForm>
            </div>
        </div>

        <div class="register-container">
            <!-- Register Form -->
            <div class="register">
                <label class="form-header">Registeer je voor ICALT</label>

                <SimpleForm target="wp-json/api/register/" :success-text="successTextRegister" alerts="html" @submit="onSubmit" @response="registerResponse">
                    <div class="mb-3 p3">
                        <label class="label-text">E-mailadres</label>
                        <input class="form-control py-4" type="email" name="email">
                    </div>

                    <div class="mb-3 p3">
                        <button class="btn btn-outline-light font-weight-bold">Account aanmaken</button>
                    </div>
                </SimpleForm>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import {useRouter} from 'vue-router';
import {Routes} from '@/router';
import useCurrentUser from '@/composeables/useCurrentUser';
import { IcaltResponse } from '@/types/IcaltResponse';
import SimpleForm from '@/components/form/SimpleForm.vue';

export default defineComponent({
    name: 'LoginScreen',
    components: {SimpleForm},
    setup() {
        const router = useRouter();
        const action = ref('login');
        const successTextLogin = ref('');
        const successTextRegister = ref('');
        const isBusy = ref(false);

        return {
            action,
            successTextLogin,
            successTextRegister,
            isBusy,
            registerResponse(response: IcaltResponse) {
                if (response.success) {
                    successTextRegister.value = String(response.data);
                }

                isBusy.value = false;
            },
            loginResponse(response: IcaltResponse) {
                if (response.success) {
                    successTextLogin.value = String(response.data);

                    // If the login attempt was successful but did not leave a message, redirect to the homepage
                    if (!response.data) {
                        useCurrentUser().clear();
                        useCurrentUser().load();
                        router.push({name: Routes.Home});
                    }
                }

                isBusy.value = false;
            },
            login() {
                action.value = 'login';
            },
            forgotPass() {
                action.value = 'forgot-password';
            },
            onSubmit() {
                isBusy.value = true;
            }
        }
    }
})
</script>
