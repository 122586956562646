<template>
    <div class="container">
        <suspense>
            <MenuBar/>
            <template #fallback><ProgressSpinner/></template>
        </suspense>

        <suspense>
            <RouterView/>
            <template #fallback><ProgressSpinner/></template>
        </suspense>
    </div>

    <GlobalModal/>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import MenuBar from '@/components/MenuBar.vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import GlobalModal from '@/components/standalone/GlobalModal.vue';

export default defineComponent({
    name: 'MainView',
    components: {GlobalModal, MenuBar, ProgressSpinner}
})
</script>
