<template>
    <template v-if="evaluation">
        <div class="layout__wrapper mb-3">
            <div class="row__header">
                <div class="mb-2">
                    <RouterLink :to="{name: Routes.EvaluationList, params: {teacherId: evaluation.teacherId}}">« Terug naar alle evaluaties</RouterLink>
                </div>
                <h1 class="text-primary fw-bold mb-0">{{ evaluation.name }}</h1>
            </div>
        </div>

        <RouterView/>
    </template>
    <ProgressSpinner v-else/>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import useEvaluation from '@/composeables/useEvaluation';
import {Routes} from '@/router';

export default defineComponent({
    name: 'EvaluationView',
    components: {ProgressSpinner},
    props: {
        evaluationId: {required: true, type: Number}
    },
    setup(props) {
        useEvaluation().load(props.evaluationId, true);

        return {
            evaluation: useEvaluation().evaluation,
            Routes
        };
    }
})
</script>
