import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-100 d-flex justify-content-center align-content-center align-items-center m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleIcon = _resolveComponent("SimpleIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SimpleIcon, {
      icon: "loading",
      size: _ctx.size
    }, null, 8, ["size"])
  ]))
}