<template>
    <SimpleForm :target="`wp-json/api/observation/${observationId}/indication/`" :extra-data="{statementId: statement.id.toString()}" @response="onResponse">
        <ul class="list-unstyled indicator-list">
            <li v-for="(indicator) in statement.indicators" :key="`${indicator.uid}`">
                <label>
                    <input type="checkbox" name="indicationUids[]" :value="indicator.uid" :checked="isSelected(indicator.uid)" :disabled="disabled" @change="onSelect">
                    &nbsp;&nbsp;{{ indicator.answer }}
                </label>
            </li>
        </ul>

        <button ref="button" class="d-none"/>
    </SimpleForm>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from 'vue';
import {Statement, StatementSchema } from '@/models/Statement';
import useObservation from '@/composeables/useObservation';
import SimpleForm from '@/components/form/SimpleForm.vue';
import {IcaltResponse} from '@/types/IcaltResponse';
import {z} from 'zod';

export default defineComponent({
    name: 'IndicationSelect',
    components: {SimpleForm},
    props: {
        disabled: {type: Boolean, default: false},
        observationId: {type: Number, required: true},
        statement: {type: Object as PropType<Statement>, required: true}
    },
    setup(props) {
        const button = ref<HTMLButtonElement>();

        return {
            button,
            isSelected(indicatorUid: string) {
                if (typeof props.statement.selectedIndications === 'undefined') {
                    return false;
                }

                return props.statement.selectedIndications.includes(indicatorUid)
            },
            onSelect() {
                button.value?.click();
            },
            onResponse(response: IcaltResponse) {
                const data = z.object({uids: z.array(z.string())}).parse(response.data);
                const statement = StatementSchema.parse({...props.statement});
                statement.selectedIndications = data.uids;
                useObservation().setStatement(statement);
            }
        }
    }
})
</script>
