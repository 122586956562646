import DetailedObservation from '@/models/DetailedObservation';
import XhrResource from '@/helpers/XhrResource';
import {Statement} from '@/models/Statement';

const resource = new XhrResource((data) => new DetailedObservation(data));

export default () => {
    return {
        observation: resource.get(),
        clear() {
            resource.clear();
        },
        load(observationId: number) {
          return resource.load(`wp-json/api/observation/${observationId}/`);
        },
        setObservation(observation: DetailedObservation) {
            if (resource.get().value) {
                resource.set(observation);
            }
        },
        setStatement(statement: Statement) {
            const observation = resource.get().value;
            if (observation) {
                for (const key in observation.statements[statement.groupId]) {
                    if (observation.statements[statement.groupId][key].id === statement.id) {
                        observation.statements[statement.groupId][key] = statement;
                    }
                }

                resource.set(observation);
            }
        }
    };
};
