import {z} from 'zod';
import Assessment, {AssessmentSchema} from '@/models/Assessment';

export const ObservationSchema = AssessmentSchema.extend({
    scoreGroup: z.record(z.number())
});

export default class Observation extends Assessment {
    public readonly scorePerGroup: {[key: string]: number};

    constructor(data: Record<string, unknown>) {
        super(data, 'observation');
        this.scorePerGroup = z.record(z.number()).parse(data.scorePerGroup);
    }
}
