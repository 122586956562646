import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "score-difference"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["score-wrap", _ctx.scoreClass])
  }, [
    _createTextVNode(_toDisplayString(_ctx.score || '?') + " ", 1),
    (_ctx.scoreDiff)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString((_ctx.scoreDiff > 0) ? '+' : '') + _toDisplayString(_ctx.scoreDiff), 1))
      : _createCommentVNode("", true)
  ], 2))
}