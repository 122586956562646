<template>
    <button type="button" :disabled="disabled || isBusy" @click.prevent="onClick">
        <slot/>
    </button>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from 'vue';
import {IcaltResponse} from '@/types/IcaltResponse';

export default defineComponent({
    name: 'FetchButton',
    props: {
        target: {type: String, required: true},
        disabled: {type: Boolean, default: false},
        method: {type: String as PropType<'GET'|'POST'>, default: 'GET'},
        confirmText: {type: String, default: ''},
    },
    emits: ['response', 'click'],
    setup(props, context) {
        const isBusy = ref(false);
        const button = ref<HTMLFormElement>();
        const result = ref<IcaltResponse>();

        return {
            button,
            result,
            isBusy,
            onClick() {
                if (!isBusy.value) {
                    if (!props.confirmText || confirm(props.confirmText)) {
                        isBusy.value = true;
                        context.emit('click');

                        fetch(`${window.location.origin}/${props.target}/`, {method: props.method}).then((jsonResponse) => {
                            jsonResponse.json().then((response: IcaltResponse) => {
                                result.value = response;
                                context.emit('response', response, button.value);
                            }).finally(() => {
                                isBusy.value = false;
                            });
                        });
                    }
                }
            }
        };
    }
})
</script>
