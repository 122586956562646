import {isBooleanOrUndefined, isNumber, isString} from '@/helpers/TypeGuard';
import Perception from '@/models/Perception';

export default class DetailedPerception extends Perception {
    public readonly shareKey: string;
    public readonly content: string;
    public readonly closingDate: string;
    public readonly lowestEducationLevel: string;
    public readonly highestEducationLevel: string;
    public readonly isCombinedClass: boolean|undefined;
    public readonly closingTimestamp: number;
    public readonly schoolId: number;
    public readonly schoolClassName: string;
    public readonly subject: string;
    public readonly grade: string;
    public readonly amountOfStudents: number;
    public readonly amountOfStudentsWithBackpack: number;

    constructor(data: Record<string, unknown>) {
        super(data);
        this.shareKey = isString(data, 'shareKey');
        this.content = isString(data, 'content');
        this.closingDate = isString(data, 'closingDate');
        this.closingTimestamp = isNumber(data, 'closingTimestamp') * 1000;
        this.schoolClassName = isString(data, 'schoolClassName');
        this.schoolId = isNumber(data, 'schoolId');
        this.lowestEducationLevel = isString(data, 'lowestEducationLevel');
        this.highestEducationLevel = isString(data, 'highestEducationLevel');
        this.isCombinedClass = isBooleanOrUndefined(data, 'isCombinedClass');
        this.subject = isString(data, 'subject');
        this.grade = isString(data, 'grade');
        this.amountOfStudents = isNumber(data, 'amountOfStudents');
        this.amountOfStudentsWithBackpack = isNumber(data, 'amountOfStudentsWithBackpack');
    }

    public getClosingDateISO() {
        if (!this.closingTimestamp) {
            return undefined;
        }

        return new Date(this.closingTimestamp).toISOString().slice(0, -8);
    }
}
