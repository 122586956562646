import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.user.avatarUrl)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _ctx.user.avatarUrl
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.user.name[0]), 1))
}