<template>
    <span v-if="isLoading" class="btn btn-primary text-white disabled cursor-not-allowed">
        <ProgressSpinner :size="28"/>
    </span>
    <button v-else type="submit" class="btn btn-primary text-white">
        <slot/>
    </button>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';

export default defineComponent({
    name: 'SubmitButton',
    components: {ProgressSpinner},
    props: {
        isLoading: {type: Boolean, default: false}
    }
})
</script>
