import XhrResource from '@/helpers/XhrResource';
import {AccessRequest, AccessRequestSchema} from '@/models/AccessRequest';

const resource = new XhrResource((data) => {
    const map = new Map<number, AccessRequest>();

    for (const item of data) {
        map.set(Number(item.id), AccessRequestSchema.parse(item));
    }

    return map;
});

export default () => ({
    load(force = false) {
        return resource.load('wp-json/api/me/access-requests/', force);
    },
    requests: resource.get()
});
