import {z, ZodType} from 'zod';

/** Check if something is a plain Javascript object */
export function isObject(obj: unknown): obj is Record<string, unknown> {
    return Boolean(typeof obj === 'object' && !Array.isArray(obj) && obj);
}

/** Map a plain Javascript object. */
export function mapObject<V, R>(obj: Record<string, V>, fn: (v: V, k: string, i: number) => R) {
    return Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));
}

/** Check if the given key exists in the given object */
export function isKeyOf<O extends Record<string, unknown>>(key: string|number|symbol, obj: O): key is keyof O {
    return key in obj;
}

/** Parses a JSON string and validates the result against a ZOD schema. Any NULL values will be stripped before the ZOD validation occurs. */
export function jsonParse<Z extends ZodType<unknown>>(text: string, schema: Z): z.infer<typeof schema> {
    return schema.parse(JSON.parse(text, (_k, v) => v === null ? undefined : v));
}

async function fetchApi<Z extends ZodType<unknown>>(url: RequestInfo|URL, schema: Z, init?: RequestInit): Promise<z.infer<typeof schema>> {
    try {
        const response = await window.fetch(url, init);
        return jsonParse(await response.text(), schema);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}
