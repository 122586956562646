import XhrResource from '@/helpers/XhrResource';
import {fetchPost} from '@/helpers/fetchHelper';
import {NofiticationSchema, Notification} from '@/models/Notification';

const resource = new XhrResource((data) => {
    const map = new Map<number, Notification>();

    for (const id in data) {
        map.set(Number(id), NofiticationSchema.parse(data[id]));
    }

    return map;
});

export default () => ({
    load() {
        return resource.load('wp-json/api/me/notifications/');
    },
    markAsRead(ids: number[]) {
        const data = new FormData();
        for (const id of ids) {
            data.set('ids[]', String(id));
        }

        fetchPost('wp-json/api/me/notifications/mark-as-read', data);
    },
    delete(id: number) {
        const notifications = resource.get().value;

        if (notifications) {
            const data = new FormData();
            data.set('ids', String(id));

            fetchPost('wp-json/api/me/notifications/delete', data).then((response) => {
                if (response.success) {
                    notifications.delete(id);
                }
            });
        }
    },
    notifications: resource.get()
});
