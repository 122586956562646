<template>
    <SimpleForm :target="`wp-json/api/observation/${observationId}/statement/`" :extra-data="{statementId: statement.id.toString()}" @response="onResponse">
        <div class="statement-score-select">
            <label v-for="(score, sKey) in 4" :key="`${statement.groupId}_${sKey}`">
                <input type="radio" :name="`score_${statement.id}`" :value="score" :checked="statement.score === score" :disabled="disabled" required @change="onSelect">
                <span>{{ score }}</span>

                <button class="d-none"/>
            </label>
        </div>
    </SimpleForm>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Statement, StatementSchema } from '@/models/Statement';
import useObservation from '@/composeables/useObservation';
import {IcaltResponse} from '@/types/IcaltResponse';
import SimpleForm from '@/components/form/SimpleForm.vue';
import {SetStatementResponseSchema} from '@/models/SetStatementResponse';

export default defineComponent({
    name: 'StatementSelect',
    components: {SimpleForm},
    props: {
        disabled: {type: Boolean, default: false},
        observationId: {type: Number, required: true},
        statement: {type: Object as PropType<Statement>, required: true}
    },
    setup(props) {
        return {
            onSelect(e: Event & {target: HTMLElement}) {
                const btn = e.target.parentElement?.lastElementChild;
                if (btn instanceof HTMLButtonElement) {
                    btn.click();
                }
            },
            onResponse(response: IcaltResponse) {
                const data = SetStatementResponseSchema.parse(response.data);
                const statement = StatementSchema.parse({...props.statement});
                statement.score = data.score;
                useObservation().setStatement(statement);
            }
        }
    }
})
</script>
