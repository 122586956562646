<template>
    <div class="user-alert access-request px-4 py-3">
        <div class="access-request__text pb-2" v-html="request.text"/>

        <template v-if="!status">
            <SimpleForm
                :alerts="'off'"
                :target="`wp-json/api/${request.type}/${request.id}/grant-access/`"
                form-classes="d-inline"
                :disabled="disabled"
                @response="onGrant"
            >
                <!--                <label>-->
                <!--                    Give access to all observations.-->
                <!--                    <input type="checkbox" value="1" name="grant-all-observations">&nbsp;-->
                <!--                </label>-->

                <button type="submit" class="btn btn-green border-0" @click="onClick">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12">
                        <path fill="#FFF" fill-rule="nonzero" d="m13.252.336 1.495 1.328-8.697 9.785L.197 5.714l1.4-1.428L5.95 8.551z"/>
                    </svg>
                </button>
            </SimpleForm>&nbsp;

            <SimpleForm
                :alerts="'off'"
                :target="`wp-json/api/${request.type}/${request.id}/deny-access`"
                form-classes="d-inline"
                :disabled="disabled"
                @response="onDeny"
            >
                <button type="submit" class="btn btn-red border-0" @click="onClick">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14">
                        <path fill="#FFF" fill-rule="nonzero" d="m12.954 2.14-4.87 4.87 4.506 4.506-1.54 1.54L6.544 8.55 1.64 13.454.024 11.837l4.903-4.903L.444 2.45 1.984.91l4.483 4.483 4.87-4.87z"/>
                    </svg>
                </button>
            </SimpleForm>
        </template>
        <template v-else-if="status === 'allowed'">
            <b>Aanvraag geaccepteerd!</b>
        </template>
        <template v-else-if="status === 'denied'">
            <b>Aanvraag geweigerd!</b>
        </template>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from 'vue';
import {IcaltResponse} from '@/types/IcaltResponse';
import SimpleForm from '@/components/form/SimpleForm.vue';
import {AccessRequest} from '@/models/AccessRequest';

export default defineComponent({
    name: 'AccessRequestAlert',
    components: {SimpleForm},
    props: {
        request: {type: Object as PropType<AccessRequest>, required: true}
    },
    setup() {
        const disabled = ref(false);
        const status = ref<'allowed'|'denied'>();

        const startTimeout = () => {
            setTimeout(() => {
                //useAccessRequests().load(true);
            }, 5000);
        }

        return {
            status,
            disabled,
            onClick() {
              disabled.value = true;
            },
            onGrant(response: IcaltResponse) {
                if (response.success) {
                    status.value = 'allowed';
                    startTimeout();
                }
            },
            onDeny(response: IcaltResponse) {
                if (response.success) {
                    status.value = 'denied';
                    startTimeout();
                }
            }
        }
    }
})
</script>
