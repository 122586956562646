import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "evaluation-general" }
const _hoisted_2 = ["target"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "text-black-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsCard = _resolveComponent("BsCard")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.evaluation)
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          ref: "form",
          target: `wp-json/api/evaluation/${_ctx.evaluationId}/info`
        }, [
          _createVNode(_component_BsCard, {
            title: "Evaluatie",
            class: "w-100"
          }, {
            default: _withCtx(() => [
              _createElementVNode("textarea", {
                class: "form-control",
                rows: "20",
                name: "evaluationContent",
                onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
                innerHTML: _ctx.evaluation.content
              }, null, 40, _hoisted_3),
              _createElementVNode("small", _hoisted_4, _toDisplayString(_ctx.statusText), 1)
            ]),
            _: 1
          })
        ], 8, _hoisted_2))
      : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 1 }))
  ]))
}