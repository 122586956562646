import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  tabindex: "0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isSecureContext)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "unstyled cursor-copy clickable-text",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyText && _ctx.copyText(...args)))
      }, [
        _createTextVNode(" 📋 "),
        _renderSlot(_ctx.$slots, "default")
      ]))
    : (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]))
}