<template>
    <svg :viewBox="viewbox" :height="`${size}px`" :width="`${size}px`" xmlns="http://www.w3.org/2000/svg" :class="`svg-icon-${icon}`">
        <path :d="path" fill="currentColor"/>
    </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

interface IconData {
    path: string,
    x: number,
    y: number
}

const icons: { [key: string]: IconData } = {
    loading: {
        x: 122,
        y: 122,
        path: 'M94.754 14.534c8.844 0 16.014 7.17 16.014 16.012 0 8.844-7.17 16.015-16.014 16.015-8.843 0-16.013-7.17-16.013-16.015 0-8.842 7.17-16.012 16.013-16.012zm14.511 37.587c-7.205 0-13.049 5.844-13.049 13.048 0 7.207 5.844 13.049 13.049 13.051 7.207 0 13.051-5.844 13.051-13.051-.001-7.204-5.844-13.048-13.051-13.048zm-15.13 37.782c-5.032 0-9.114 4.082-9.114 9.113 0 5.032 4.082 9.114 9.114 9.114 5.031 0 9.113-4.082 9.113-9.114 0-5.031-4.082-9.113-9.113-9.113zm-34.86 14.747c-5.032 0-9.114 4.081-9.114 9.113 0 5.034 4.082 9.116 9.114 9.116s9.113-4.082 9.113-9.116c.001-5.032-4.08-9.113-9.113-9.113zM23.652 90.86c-4.717 0-8.54 3.823-8.54 8.54 0 4.715 3.823 8.54 8.54 8.54 4.714 0 8.538-3.825 8.538-8.54 0-4.716-3.824-8.54-8.538-8.54zM9.096 54.872C4.072 54.872 0 58.944 0 63.968c0 5.021 4.072 9.093 9.096 9.093 5.021 0 9.093-4.072 9.093-9.093 0-5.024-4.073-9.096-9.093-9.096zm14.556-37.846c-6.354 0-11.508 5.155-11.508 11.509s5.154 11.506 11.508 11.506 11.506-5.152 11.506-11.506-5.152-11.509-11.506-11.509zM59.341 0C51.69 0 45.483 6.205 45.483 13.855c0 7.651 6.207 13.856 13.858 13.856s13.856-6.205 13.856-13.856C73.197 6.205 66.992 0 59.341 0z'
    }
} as const;

export default defineComponent({
    name: 'SimpleIcon',
    props: {
        icon: {type: String, required: true},
        size: {type: Number, default: 22}
    },
    setup(props) {
        return {
            path: icons[props.icon].path,
            viewbox: `0 0 ${icons[props.icon].x} ${icons[props.icon].y}`
        };
    }
})
</script>
