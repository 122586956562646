import {StatementGroupSchema} from '@/models/StatementGroup';
import XhrResource from '@/helpers/XhrResource';
import {mapObject} from '@/helpers/objectHelper';

const resource = new XhrResource((data: Record<string, unknown>) => {
    return mapObject(data, (o) => StatementGroupSchema.parse(o));
});

export default () => ({
    load() {
        return resource.load(`wp-json/api/statement-groups/`);
    },
    clear: () => resource.clear(),
    statementGroups: resource.get(),
    // indexedStatementGroups: computed(() => {
    //     const groups = resource.get().value;
    //     if (!groups) {
    //         return undefined;
    //     }
    //
    //     const indexed = {};
    //     for (key in groups) {
    //
    //     }
    // })
});
