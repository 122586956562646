<template>
    <Popper v-if="requests?.size" placement="left" offset-distance="-60px">
        <button class="btn position-relative border-0 notification-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28">
                <path fill="#212121" fill-rule="nonzero" d="m15.835 24.576.997.667-.333.499A4.207 4.207 0 0 1 13 27.6a4.207 4.207 0 0 1-3.499-1.858l-.333-.5.997-.666.334.499A3.007 3.007 0 0 0 13 26.4a3.007 3.007 0 0 0 2.501-1.325l.334-.5ZM13 .4C8.031.4 4 4.37 4 9.273v3.545c0 1.423-.448 2.403-1.45 3.566L1.453 17.59c-.043.05-.08.091-.106.123C.717 18.468.4 19.123.4 19.909c0 2.51 4.966 4.09 12.21 4.144l.39.002c7.455 0 12.6-1.592 12.6-4.146 0-.786-.317-1.441-.948-2.195l-.424-.476a74.322 74.322 0 0 0-.583-.634C22.505 15.342 22 14.33 22 12.818V9.273C22 4.37 17.969.4 13 .4Zm0 1.2c4.31 0 7.8 3.437 7.8 7.673v3.545c0 1.756.566 2.985 1.74 4.348l.288.321c.184.2.663.72.858.944l.046.053c.467.558.668.973.668 1.425 0 1.484-4.463 2.892-11.026 2.944l-.374.002c-6.767 0-11.4-1.434-11.4-2.946 0-.406.163-.783.536-1.262l.132-.163a56.754 56.754 0 0 1 .977-1.076c1.32-1.462 1.955-2.731 1.955-4.59V9.273C5.2 5.037 8.69 1.6 13 1.6Z"/>
            </svg>

            <span v-if="requests?.size > 99" class="notification-count notification-count__small position-absolute fw-bold text-white">99+</span>
            <span v-else class="notification-count position-absolute fw-bold text-white">{{ requests?.size }}</span>
        </button>

        <template #content>
            <div class="user-alerts access-requests pt-3">
                <div class="px-4 pb-3 text-primary fw-bold">Notifications</div>
                <template v-for="[id, request] in requests" :key="id">
                    <AccessRequestAlert :request="request"/>
                </template>
            </div>

            <div v-if="notifications" class="user-alerts user-notifications">
                <template v-for="[id, notification] in notifications" :key="id">
                    <div class="user-alert user-notification">
                        {{ notification.text }}
                        <button type="button" @click="() => deleteNotification(notification.id)">🗑️</button>
                    </div>
                    <hr>
                </template>
            </div>
        </template>
    </Popper>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Popper from 'vue3-popper';
import useAccessRequests from '@/composeables/useAccessRequests';
import AccessRequestAlert from '@/components/alerts/AccessRequestAlert.vue';
import useNotifications from '@/composeables/useNotifications';

export default defineComponent({
    name: 'UserAlerts',
    components: {AccessRequestAlert, Popper},
    setup() {
        useAccessRequests().load();
        useNotifications().load();

        return {
            requests: useAccessRequests().requests,
            notifications: useNotifications().notifications,
            deleteNotification(id: number) {
                useNotifications().delete(id);
            }
        }
    }
})
</script>
