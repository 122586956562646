import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "layout__wrapper mb-3" }
const _hoisted_2 = { class: "row__header" }
const _hoisted_3 = { class: "mb-2" }
const _hoisted_4 = { class: "text-primary fw-bold mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_ctx.evaluation)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_RouterLink, {
                to: {name: _ctx.Routes.EvaluationList, params: {teacherId: _ctx.evaluation.teacherId}}
              }, {
                default: _withCtx(() => [
                  _createTextVNode("« Terug naar alle evaluaties")
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.evaluation.name), 1)
          ])
        ]),
        _createVNode(_component_RouterView)
      ], 64))
    : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 1 }))
}