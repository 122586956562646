import {UserSchema} from '@/models/User';
import {z} from 'zod';

export const CurrentUserSchema = UserSchema.extend({
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    schoolIds: z.array(z.number()).optional()
});

export type CurrentUser = z.infer<typeof CurrentUserSchema>;
